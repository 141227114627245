import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebase'; // Assuming you have Firebase storage configured

// Function to fetch image URL from Firebase Storage
const fetchImage = (filename) => {
    
  try {
    const imageURL = getDownloadURL(ref(storage, filename));
    //console.log('fetchimage called', imageURL);
    return imageURL;
  } catch (error) {
    //console.error(`Error fetching image ${filename}:`, error);
    return ''; // Return empty string in case of error
  }
};

// Export image variables individually with their respective URLs
export const privateSedanImage = await fetchImage('private_sedan.png');
export const privateSUVImage = await fetchImage('private_suv.png');
export const privateLuxurySedanImage = await fetchImage('private_luxury_sedan_or_suv.png');
export const privateMinivanImage = await fetchImage('private_minivan.png');
export const privateLuxuryBlackSUVImage = await fetchImage('private_luxury_black_suv.png');
