import React, { useState, useEffect } from 'react';
import BookingForm from './components/BookingForm';
import './assets/styles/Home.css';
import { Link } from 'react-router-dom';
import AssistantSharpIcon from '@mui/icons-material/AssistantSharp';
import AccessAlarmSharpIcon from '@mui/icons-material/AccessAlarmSharp';
import DriverIcon from './assets/images/driver_icon.png'
import TrustIcon from './assets/images/trust_icon.png'
import PaidSharpIcon from '@mui/icons-material/PaidSharp';


const scrollDownToForm = () => {
  const formContainer = document.getElementById('booking-form');
  formContainer.scrollIntoView({ behavior: 'smooth' });
};

const Home = () => {
  

  return (
    <div class="div">
  <div class="div-9">
    <div class="div-10">
      <div class="div-11">
        Reliable Airport Transportation Services for a Hassle-Free Journey
      </div>
      {/* <div class="div-12">
        At Lux Transport, we provide top-notch airport
        transportation services. With our fleet of cars, we ensure a comfortable
        and convenient ride to and from the airport. Book your ride today and
        experience a hassle-free journey.
      </div> */}
    </div>
    <div class="div-13">
      <button class="div-14" onClick={scrollDownToForm}>Make A Booking</button>
      <Link to="/destinations">
        <button class="div-15">Destinations</button>
      </Link>
    </div>
  </div>
  <div class="div-16">
    <div class="div-17">Experience the Best Airport Transportation Service</div>
    <div class="div-18">
      Our airport transportation service offers reliable and safe transportation
      with professional drivers. We prioritize your safety and ensure a
      comfortable journey from start to finish.
    </div>
    <div class="div-19">
      <div class="div-20">
        <div class="column">
          <div class="div-21">
            <img
              loading="lazy"
              src={TrustIcon}
              class="img-2"
            />
            <div class="div-22">Reliable and Safe Transportation Services</div>
            <div class="div-23">
              We provide reliable and safe transportation services to and from
              the airport, ensuring a stress-free travel experience.
            </div>
          </div>
        </div>
        <div class="column">
          <div class="div-21">
            <img
              loading="lazy"
              src={DriverIcon}
              class="img-3"
            />
            <div class="div-22">Professional and Courteous Drivers</div>
            <div class="div-23">
              Our team of professional and courteous drivers will ensure a
              smooth and enjoyable journey.
            </div>
          </div>
        </div>
        <div class="column">
          <div class="div-21">
            <AccessAlarmSharpIcon
              loading="lazy"
              class="img-4"
            />
            <div class="div-22">On-Time and Efficient Service</div>
            <div class="div-23">
              We pride ourselves on providing on-time and efficient service,
              getting you to your destination without any delays.
            </div>
          </div>
        </div>
        <div class="column">
          <div class="div-21">
            <PaidSharpIcon
              loading="lazy"
              class="img-4"
            />
            <div class="div-22">Free Cancellation & No Hidden Fees</div>
            <div class="div-23">
              Cancel your bookings effortlessly with our 24-hour cancellation policy, providing you the flexibility to adjust your plans without any hassle. No hidden fees added to your bookings!
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

    <BookingForm />

  <div class="div-54">
    <div class="div-55">
      Experience a hassle-free booking process for your transportation services.
    </div>
    <div class="div-56">
      <div class="div-57">
        <div class="column">
          <div class="div-58">
            <AssistantSharpIcon
              loading="lazy"
              class="img-8"
            />
            <div class="div-59">
              Enjoy a seamless booking experience for your transportation needs.
            </div>
            <div class="div-60">
              Simply select your pickup and drop-off locations, choose your
              preferred vehicle, and book your ride.
            </div>
          </div>
        </div>
        <div class="column">
          <div class="div-58">
            <AssistantSharpIcon
              loading="lazy"
              class="img-9"
            />
            <div class="div-59">
              Get to your destination effortlessly with our easy-to-use booking
              system.
            </div>
            <div class="div-60">
              Just enter your travel details, select your vehicle, and confirm
              your reservation.
            </div>
          </div>
        </div>
        <div class="column">
          <div class="div-58">
            <AssistantSharpIcon
              loading="lazy"
              class="img-10"
            />
            <div class="div-59">
              Experience the convenience of booking your transportation with
              just a few clicks.
            </div>
            <div class="div-60">
              Choose your pickup and drop-off points, select your vehicle, and
              finalize your booking.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

  


  );
};

export default Home;


