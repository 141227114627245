import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, signInWithGoogle, db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserEmail = async (email) => {
      const allowedEmailsCollection = collection(db, 'users');
      const querySnapshot = await getDocs(allowedEmailsCollection);
      const allowedEmails = querySnapshot.docs.map((doc) => doc.data().email);

      if (!allowedEmails.includes(email)) {
        // If the user's email is not allowed, do not redirect to the admin page
        //console.log('Email not allowed. Redirect to login page or handle accordingly.');
      } else {
        // If the user's email is allowed, redirect to the admin page
        navigate('/admin');
      }
    };

    // Check if the user is already signed in
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // If the user is signed in, check their email and redirect accordingly
        checkUserEmail(user.email);
      }
    });

    // Clean up the subscription when the component unmounts
    return () => unsubscribe();
  }, [navigate]);

  const handleSignIn = async () => {
    try {
      // Trigger Google Sign-In popup
      await signInWithGoogle();
    } catch (error) {
      //console.error('Error signing in:', error);
    }
  };

  return (
    <div style={{ textAlign: 'center', margin: '20px', height: '50vh' }}>
      <h1>Login Page</h1>
      <p>Welcome! Please sign in to access the admin page.</p>
      <button style={{ width: '200px'}} onClick={handleSignIn}>Sign In with Google</button>
    </div>
  );
};

export default Login;
