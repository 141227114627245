import React, { useState } from 'react';

const FAQItem = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  const toggleAnswer = () => {
    //console.log('clicked')
    setShowAnswer(!showAnswer);
  };

  return (
    <div>
    <div className="faq-div-10" onClick={toggleAnswer}>
      <div className="faq-div-11">{question}</div>
      <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a428e65-a775-4d4e-88ce-e4cf109abc4c?apiKey=4a969b8594f0435493444d2b236405f7&"
                class="faq-img"
              />
      
    </div>
    
    {showAnswer && (
        <div className="faq-div-10">
          <div>{answer}</div>
        </div>
      )}
      </div>
  );
};

export default FAQItem;
