import React from 'react';
import { WhatsApp } from '@mui/icons-material';

const WhatsAppButton = () => {
  const handleWhatsAppClick = () => {
    // Handle the action when the button is clicked, e.g., open a WhatsApp chat
    //todo: update phone number
    const phoneNumber = '13828855589';
    const message = encodeURIComponent('');

    window.open(`https://wa.me/${phoneNumber}?text=${message}`);
  };

  return (
    <div className="whatsapp-button" onClick={handleWhatsAppClick}>
      <WhatsApp class='whatsapp-button-icon'/>
    </div>
  );
};

export default WhatsAppButton;