import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS
import { addDoc, collection, getDocs, query, where, orderBy, limit, Firestore, serverTimestamp, getDoc, doc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import { isEmpty } from 'lodash';
import '../assets/styles/BookingForm.css'; 
import { sendEmail, getAdminEmail } from '../services/EmailService';
import PayPalPayment from '../services/PayPalPayment';
import { addMinutes, startOfMinute, format, addHours } from 'date-fns'; 
import CarSegment from './CarSegment';
import { Link } from 'react-router-dom';

import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import VisaSVG from '../assets/images/visa.svg'
import MasterCardSVG from '../assets/images/mastercard.svg'
import AmericanExpressSVG from '../assets/images/americanexpress.svg'
import JCBSVG from '../assets/images/jcb.svg'

import 'react-phone-number-input/style.css'
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input/input'

const BookingForm = () => {
  const [currentPage, setCurrentPage] = useState(0);

  const [pickupDate, setPickupDate] = useState(null);
  const [pickupLocation, setPickupLocation] = useState('');
  const [dropOffLocation, setDropOffLocation] = useState('');
  const [peopleCount, setPeopleCount] = useState(1);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [tipTheDriver, setTipTheDriver] = useState(false);
  const [infantSeat, setInfantSeat] = useState(false);
  const [childSeat, setChildSeat] = useState(false);
  const [handBaggage, setHandBaggage] = useState(false);
  const [baggage, setBaggage] = useState(false);
  const [additionalPickupDropOff, setAdditionalPickupDropOff] = useState(false);

  //return inputs
  const [isReturn, setIsReturn] = useState(false);
  const [returnDate, setReturnDate] = useState(null);
  const [returnPickupLocation, setReturnPickupLocation] = useState('');
  const [returnDropOffLocation, setReturnDropOffLocation] = useState('');
  const [returnSelectedVehicle, setReturnSelectedVehicle] = useState('');
  const [returnPeopleCount, setReturnPeopleCount] = useState(1);
  const [returnTipTheDriver, setReturnTipTheDriver] = useState(false);
  const [returnInfantSeat, setReturnInfantSeat] = useState(false);
  const [returnChildSeat, setReturnChildSeat] = useState(false);
  const [returnHandBaggage, setReturnHandBaggage] = useState(false);
  const [returnBaggage, setReturnBaggage] = useState(false);
  const [returnAdditionalPickupDropOff, setReturnAdditionalPickupDropOff] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [userAddress, setUserAddress] = useState({
    line1: '',
    line2: '',
    city: '',
    province: 'Ontario',
    postalCode: '',
  });
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');



  // Define prices for additional options
  const infantSeatPrice = 20;
  const childSeatPrice = 20;
  const handBaggagePrice = 0;
  const baggagePrice = 0;
  const additionalPickupDropOffPrice = 20;
  const tipPrice = 20;

  const [confirmedBooking, setConfirmedBooking] = useState(null);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false); 

  const [airports, setAirports] = useState([]);
  const [otherLocations, setOtherLocations] = useState([]);
  const [workbook, setWorkbook] = useState(null);

  //fetching promo code
  const [promoCode, setPromoCode] = useState('');

  useEffect(() => {
    const fetchPromoCode = async () => {
      try {
        const promoDocRef = doc(db, 'promoCodes', 'currentPromo');
        const promoDocSnap = await getDoc(promoDocRef);
        if (promoDocSnap.exists()) {
          const promoData = promoDocSnap.data();
          setPromoCode(promoData);
        } else {
          setPromoCode(null);
        }
      } catch (error) {
        //console.error('Error fetching promo code:', error);
      }
    };

    fetchPromoCode();
  }, []);

  // Function to calculate the deduction amount based on the applied promo code
  const calculatePromoDeduction = () => {
    const totalBeforePromo = calculateTotalPrice() + calculateReturnTotalPrice(); // Calculate the total before applying promo
    
    if (!promoCode.isActive) {
      return 0; // If the promo code is inactive, no deduction
    }

    // Check if the total before promo is less than the minimum payment amount
    if (totalBeforePromo < promoCode.minPaymentAmount) {
      return 0; // If total is less than minimum payment amount, no deduction
    }

    let promoAmount = totalBeforePromo * (promoCode.percentage / 100); // Calculate the promo amount
    
    // Cap the promo amount to the maxDiscountAmount if it exceeds
    promoAmount = Math.min(promoAmount, promoCode.maxDiscountAmount);

    return promoAmount; // Return the calculated promo amount to be deducted
  };

  

  const handleVehicleSelection = (vehicleTitle) => {
    setSelectedVehicle(vehicleTitle);
  };

  const handleReturnVehicleSelection = (vehicleTitle) => {
    setReturnSelectedVehicle(vehicleTitle);
  };

  
  // Function to go to the next page
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  // Function to go back to the previous page
  const prevPage = () => {
    if(currentPage === 3){
      setCurrentPage(2);
    } else if (currentPage === 2) {
      setCurrentPage(1);
    } else if (currentPage === 4) {
      setCurrentPage(3);
    }
    
  };

  // Function to handle "Next" button click on the 3rd page
  const handleNextToConfirmation = () => {

    // set city to either pickup or drop-off location
    setUserAddress({
        ...userAddress,
        city: isAirport(pickupLocation) ? dropOffLocation : pickupLocation,
      });

    // Create an object with booking details
    const bookingData = {
      pickupDate: pickupDate,
      pickupLocation: pickupLocation, 
      dropOffLocation: dropOffLocation, 
      isReturn: isReturn,
      returnDate: returnDate,
      returnPickupLocation: returnPickupLocation, 
      returnDropOffLocation: returnDropOffLocation, 
      selectedVehicle: selectedVehicle, 
      peopleCount: peopleCount,
      infantSeat: infantSeat,
      tipTheDriver: tipTheDriver,
      childSeat: childSeat,
      handBaggage: handBaggage,
      baggage: baggage,
      additionalPickupDropOff: additionalPickupDropOff
    };
    
    // If it's a return trip, append return trip data
    if (isReturn) {
      const returnBookingData = {
        returnDate: returnDate,
        returnPickupLocation: returnPickupLocation,
        returnDropOffLocation: returnDropOffLocation,
        returnSelectedVehicle: returnSelectedVehicle,
        returnPeopleCount: returnPeopleCount,
        returnInfantSeat: returnInfantSeat,
        returnTipTheDriver: returnTipTheDriver,
        returnChildSeat: returnChildSeat,
        returnHandBaggage: returnHandBaggage,
        returnBaggage: returnBaggage,
        returnAdditionalPickupDropOff: returnAdditionalPickupDropOff,
      };

      // Merge the return trip data with the original booking data
      const confirmedBookingData = { ...bookingData, ...returnBookingData };
      
      // Update the state with the confirmation data
      setConfirmedBooking(confirmedBookingData);
    } else {
      // Update the state with the confirmation data for one-way trip
      setConfirmedBooking(bookingData);
    }

    // Navigate to the 4th page (Confirmation)
    setCurrentPage(4);
    setShowFourthPage(true);
  };

  const validatePaymentPage = () => {
    const errors = {};

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userEmail || !emailRegex.test(userEmail)) {
      errors.userEmail = 'Please enter a valid email address';
    }

    // Postal Code validation for Ontario format
    const postalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    if (!userAddress.postalCode || !postalCodeRegex.test(userAddress.postalCode)) {
      errors.userAddressPostalCode = 'Please enter a valid Ontario postal code';
    }

    // Phone number validation for North American format
    // const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    // if (!userPhone || !phoneRegex.test(userPhone)) {
    //   errors.userPhone = 'Please enter a valid phone number such as 123-123-1212';
    // }

    if (!userPhone || !isPossiblePhoneNumber(userPhone)){
      errors.userPhone = 'Please enter a valid phone number such as (XXX) XXX-XXXX or 1 (XXX) XXX-XXXX';
    }

    // Other required fields validation
    if (!userAddress.line1) {
      errors.userAddressLine1 = 'Address Line 1 is required';
    }

    if (!userAddress.city) {
      errors.userAddressCity = 'City is required';
    }

    if (!userAddress.province) {
      errors.userAddressProvince = 'Province is required';
    }

    // Set the validation errors state
    setValidationErrorsPayment(errors);

    // Check if there are any errors
    return Object.keys(errors).length === 0;
  }

  const handlePaymentPage = () => {
    //console.log('handplepayment page called')
    const isValid = validatePaymentPage();
    if (isValid && selectedPaymentMethod) {
      //console.log('handplepayment page called2')
      setPaymentSuccessful(true);
      
    } else if (isValid && !selectedPaymentMethod){
      const errors = {};
      errors.selectedPaymentMethod = 'Please select a payment method';
      setValidationErrorsPayment(errors);

      // Scroll to the error section and set focus
      const errorSection = document.getElementById("payment-error-section");
      if (errorSection) {
        errorSection.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  }

  useEffect(() => {
    // This effect will run whenever paymentSuccessful changes
    if (paymentSuccessful) {
      saveBookingToDb();
    }
  }, [paymentSuccessful]);

  const saveBookingToDb = async () => {
      let totalPrice = calculateTotalPrice() + calculateReturnTotalPrice() - calculatePromoDeduction();

      const bookingsCollection = collection(db, 'bookings');
      const querySnapshot = await getDocs(query(bookingsCollection, orderBy('orderNumber', 'desc'), limit(1)));

      let orderNumber = 1;
      if (!isEmpty(querySnapshot.docs)) {
        orderNumber = querySnapshot.docs[0].data().orderNumber + 1;
      }

      const newUserData = {
        userEmail: userEmail,
        userPhone: userPhone,
        userAddress: userAddress,
        selectedPaymentMethod: selectedPaymentMethod,
        price: calculateTotalPrice(),
        returnPrice: calculateReturnTotalPrice(),
        totalPrice: totalPrice,
        orderNumber: orderNumber,
        bookingDate: serverTimestamp(),
        promoApplied: promoCode
      };

      const confirmedBookingToDb = {
      ...confirmedBooking,
      ...newUserData,
    };

      // Save the booking data to Firebase Firestore
      if(confirmedBookingToDb && paymentSuccessful){
        try {
          await addDoc(bookingsCollection, confirmedBookingToDb); // Add booking data to Firestore
          //alert('Booking details saved successfully!');

          const adminEmail = await getAdminEmail();
          // Send an email to the admin and user
          sendEmail(confirmedBookingToDb, adminEmail);
        } catch (error) {
          //console.error('Error saving booking details:', error);
        }
      } 

      resetSelections();
    
    
  };

  const resetSelections = () => {
    setShowSecondPage(false);
    setShowThirdPage(false);
    setShowFourthPage(false);
    setShowSuccessPage(true);
    setCurrentPage(0);

    //departure
    setPickupDate(null);
    setPickupLocation('');
    setDropOffLocation('');
    setSelectedVehicle('');
    setSelectedPaymentMethod('');
    setPeopleCount(1);
    setTipTheDriver(false);
    setInfantSeat(false);
    setChildSeat(false);
    setHandBaggage(false);
    setBaggage(false);
    setAdditionalPickupDropOff(false);

    //return
    setIsReturn(false);
    setReturnDate(null);
    setReturnPickupLocation('');
    setReturnDropOffLocation('');
    setReturnSelectedVehicle('');
    setReturnPeopleCount(1);
    setReturnTipTheDriver(false);
    setReturnInfantSeat(false);
    setReturnChildSeat(false);
    setReturnHandBaggage(false);
    setReturnBaggage(false);
    setReturnAdditionalPickupDropOff(false);

    setUserAddress({
      line1: '',
      line2: '',
      city: '',
      province: 'Ontario',
      postalCode: '',
    });
    setUserEmail('');
    setUserPhone('');
    setConfirmedBooking(null);
    setPaymentSuccessful(false); 

    setValidationErrorsInitial({});
    setValidationErrorsCar({});
    setValidationErrorsPayment({});
    //window.location.reload();
  };

  // Function to calculate the total price
  const calculateTotalPrice = () => {
    // Initialize the total price with the base vehicle price
    let totalPrice = 0;

    // Calculate the base vehicle price based on the selected vehicle
    if (confirmedBooking.selectedVehicle.toLowerCase() === 'Private Sedan'.toLowerCase()) {
      totalPrice += privateSedanPrice; 
    } else if (confirmedBooking.selectedVehicle.toLowerCase() === 'Private Minivan'.toLowerCase()) {
      totalPrice += privateMinivanPrice; 
    } else if (confirmedBooking.selectedVehicle.toLowerCase() === 'Private SUV'.toLowerCase()) {
      totalPrice += privateSUVPrice; 
    } else if (confirmedBooking.selectedVehicle.toLowerCase() === 'Private Luxury Sedan'.toLowerCase()) {
      totalPrice += privateLuxurySedanPrice; 
    } else if (confirmedBooking.selectedVehicle.toLowerCase() === 'Private Luxury Black SUV'.toLowerCase()) {
      totalPrice += privateLuxuryBlackSUVPrice; 
    }


    // Add prices for additional options
    totalPrice += infantSeatPrice * confirmedBooking.infantSeat;
    totalPrice += childSeatPrice * confirmedBooking.childSeat;
    totalPrice += handBaggagePrice * confirmedBooking.handBaggage;
    totalPrice += baggagePrice * confirmedBooking.baggage;
    totalPrice += additionalPickupDropOffPrice * confirmedBooking.additionalPickupDropOff;
    totalPrice += tipPrice * confirmedBooking.tipTheDriver;
    
    return totalPrice;
  };

  const calculateReturnTotalPrice = () => {
    // Initialize the total price with the base vehicle price
    let totalPrice = 0;
    if (isReturn){
      // Calculate the base vehicle price based on the selected vehicle
      if (confirmedBooking.returnSelectedVehicle.toLowerCase() === 'Private Sedan'.toLowerCase()) {
        totalPrice += returnPrivateSedanPrice; 
      } else if (confirmedBooking.returnSelectedVehicle.toLowerCase() === 'Private Minivan'.toLowerCase()) {
        totalPrice += returnPrivateMinivanPrice; 
      } else if (confirmedBooking.returnSelectedVehicle.toLowerCase() === 'Private SUV'.toLowerCase()) {
        totalPrice += returnPrivateSUVPrice; 
      } else if (confirmedBooking.returnSelectedVehicle.toLowerCase() === 'Private Luxury Sedan'.toLowerCase()) {
        totalPrice += returnPrivateLuxurySedanPrice; 
      } else if (confirmedBooking.returnSelectedVehicle.toLowerCase() === 'Private Luxury Black SUV'.toLowerCase()) {
        totalPrice += returnPrivateLuxuryBlackSUVPrice; 
      }

      // Add prices for additional options
      totalPrice += infantSeatPrice * confirmedBooking.returnInfantSeat;
      totalPrice += childSeatPrice * confirmedBooking.returnChildSeat;
      totalPrice += handBaggagePrice * confirmedBooking.returnHandBaggage;
      totalPrice += baggagePrice * confirmedBooking.returnBaggage;
      totalPrice += additionalPickupDropOffPrice * confirmedBooking.returnAdditionalPickupDropOff;
      totalPrice += tipPrice * confirmedBooking.returnTipTheDriver;
    }
    
    
    return totalPrice;
  };

  

  // Define a state for error messages
  const [validationErrorsInitial, setValidationErrorsInitial] = useState({});
  const [validationErrorsCar, setValidationErrorsCar] = useState({});
  const [validationErrorsPayment, setValidationErrorsPayment] = useState({});


  const [showFirstPage, setShowFirstPage] = useState(false);
  const [showSecondPage, setShowSecondPage] = useState(false);
  const [showThirdPage, setShowThirdPage] = useState(false);
  const [showFourthPage, setShowFourthPage] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  function isAirport(location) {
    return airports.includes(location);
  }

  function isOtherLocation(location) {
    return otherLocations.includes(location);
  }

  // Function to validate the fields on the first page
  const validatePage1 = () => {
    const errors = {};

    // Validate pickupDate
    if (!pickupDate || pickupDate < startOfMinute(new Date())) {
      errors.pickupDate = 'Please select a valid pickup date and time';
    }

    // Validate pickupDate to allow bookings 15 hours later
    const currentTime = new Date();
    const minimumPickupTime = addHours(currentTime, 15);

    if (pickupDate < minimumPickupTime) {
      errors.pickupDate = 'Please select a pickup date and time that is at least 15 hours later';
    }

    // Validate pickupLocation
    if (!pickupLocation || pickupLocation === '' || pickupLocation === dropOffLocation) {
      errors.pickupLocation = 'Please select a valid pickup location';
    } 

    if ((isOtherLocation(pickupLocation) && isOtherLocation(dropOffLocation))) {
      errors.pickupLocation = 'Please select an Airport either as pickup or drop-off location';
    }

    if ((isAirport(pickupLocation) && isAirport(dropOffLocation))) {
      errors.pickupLocation = 'Please select one of the Other Locations either as pickup or drop-off location';
    }

    // Validate dropOffLocation
    if (!dropOffLocation || dropOffLocation === '' || dropOffLocation === pickupLocation) {
      errors.dropOffLocation = 'Please select a valid drop-off location';
    } 

    // Validate returnDate if it's a return trip
    if (isReturn && (!returnDate || returnDate < addMinutes(startOfMinute(new Date()), 30) || returnDate <= pickupDate)) {
      errors.returnDate = 'Please select a valid return pickup date and time';
    }

    // Validate returnPickupLocation if it's a return trip
    if (isReturn && (!returnPickupLocation || returnPickupLocation === 'Select a location' || returnPickupLocation === returnDropOffLocation)) {
      errors.returnPickupLocation = 'Please select a valid return pickup location';
    }

    // Validate returnDropOffLocation if it's a return trip
    if (isReturn && (!returnDropOffLocation || returnDropOffLocation === 'Select a location' || returnDropOffLocation === returnPickupLocation)) {
      errors.returnDropOffLocation = 'Please select a valid return drop-off location';
    }

    // Validate peopleCount
    if (!peopleCount || peopleCount < 1) {
      errors.peopleCount = 'Please enter a valid number of people';
    }

    if (peopleCount > 7) {
      errors.peopleCount = 'Please enter a valid number of people, you can only make a booking for 7 people at once';
    }

    // Validate returnPeopleCount if it's a return trip
    if (isReturn && (!returnPeopleCount || returnPeopleCount < 1)) {
      errors.returnPeopleCount = 'Please enter a valid number of people';
    }

    if (isReturn && (returnPeopleCount > 7)) {
      errors.returnPeopleCount = 'Please enter a valid number of people, you can only make a booking for 7 people at once';
    }

    // Set the validation errors state
    setValidationErrorsInitial(errors);

    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };

  const [privateSedanPrice, setPrivateSedanPrice] = useState(0);
  const [privateSUVPrice, setPrivateSUVPrice] = useState(0);
  const [privateLuxurySedanPrice, setPrivateLuxurySedanPrice] = useState(0);
  const [privateMinivanPrice, setPrivateMinivanPrice] = useState(0);
  const [privateLuxuryBlackSUVPrice, setPrivateLuxuryBlackSUVPrice] = useState(0);

  const [returnPrivateSedanPrice, setReturnPrivateSedanPrice] = useState(0);
  const [returnPrivateSUVPrice, setReturnPrivateSUVPrice] = useState(0);
  const [returnPrivateLuxurySedanPrice, setReturnPrivateLuxurySedanPrice] = useState(0);
  const [returnPrivateMinivanPrice, setReturnPrivateMinivanPrice] = useState(0);
  const [returnPrivateLuxuryBlackSUVPrice, setReturnPrivateLuxuryBlackSUVPrice] = useState(0);

  const getSegmentPrice = (segmentTitle) => {
    switch (segmentTitle.toLowerCase()) {
      case 'Private Sedan'.toLowerCase():
        return privateSedanPrice;
      case 'Private SUV'.toLowerCase():
        return privateSUVPrice;
      case 'Private Luxury Sedan'.toLowerCase():
        return privateLuxurySedanPrice;
      case 'Private Minivan'.toLowerCase():
        return privateMinivanPrice;
      case 'Private Luxury Black SUV'.toLowerCase():
        return privateLuxuryBlackSUVPrice;
      default:
        return 0; // Or whatever default price you want to use
    }
  };

  const getReturnSegmentPrice = (returnSegmentTitle) => {
    switch (returnSegmentTitle.toLowerCase()) {
      case 'Private Sedan'.toLowerCase():
        return returnPrivateSedanPrice;
      case 'Private SUV'.toLowerCase():
        return returnPrivateSUVPrice;
      case 'Private Luxury Sedan'.toLowerCase():
        return returnPrivateLuxurySedanPrice;
      case 'Private Minivan'.toLowerCase():
        return returnPrivateMinivanPrice;
      case 'Private Luxury Black SUV'.toLowerCase():
        return returnPrivateLuxuryBlackSUVPrice;
      default:
        return 0; // Or whatever default price you want to use
    }
  };


  useEffect(() => {
    const fetchLocationNames = async () => {
      try {
        // Fetch the Excel file from the public folder only if it hasn't been fetched yet
        if (!workbook) {
          //fetch excel file from public folder
          //const response = await fetch(process.env.PUBLIC_URL + '/trip_prices.xlsx');

          //fetch excel file from database
          const storageRef = ref(storage, 'trip_prices.xlsx');
          const downloadURL = await getDownloadURL(storageRef);
          const response = await fetch(downloadURL);
          const data = await response.arrayBuffer();

          // Convert the array buffer to a binary string
          const binaryString = String.fromCharCode.apply(null, new Uint8Array(data));

          // Read the Excel file
          const workbook = XLSX.read(binaryString, { type: 'binary' });

          // Set the workbook in state for reuse
          setWorkbook(workbook);
        }

        // Choose the sheet
        const sheet = workbook.Sheets['SHEET1'];

        // Assuming the location names are in the 'PICK UP LOCATION' column
        const locationNames = XLSX.utils.sheet_to_json(sheet, { header: 0 }).map((row) => row['PICK UP LOCATION']);

        // Use a Set to keep track of unique names
        const uniqueNames = new Set(locationNames);

        // Filter locations containing the word "airport"
        const airportLocations = [...uniqueNames].filter(location => location.toLowerCase().includes('airport'));

        setAirports(airportLocations);
        setOtherLocations([...uniqueNames].filter(location => !airportLocations.includes(location)));

      } catch (error) {
        //console.error('Error fetching or parsing Excel file:', error);
      }
    };

    fetchLocationNames();
  }, [workbook]);

  const [carSegments, setCarSegments] = useState([]);

  useEffect(() => {
    const fetchCarSegments = async () => {
      try {
        const carSegmentsCollection = collection(db, 'carSegments'); // Firestore collection name
        const snapshot = await getDocs(carSegmentsCollection);
        const carSegmentsData = snapshot.docs.map(doc => doc.data());
        setCarSegments(carSegmentsData);
      } catch (error) {
        //console.error('Error fetching car segments:', error);
      }
    };

    fetchCarSegments();
  }, []);

  const XLSX = require('xlsx');

  const handleCarDetails = () => {
        // Check if the workbook is available
        if (!workbook) {
          //console.error('Workbook not available. Make sure to fetch location names first.');
          return;
        }

        // Choose the sheet
        const sheet = workbook.Sheets['SHEET1'];

        // Find the row corresponding to the pickup and drop-off locations
        const pickupCol = 'PICK UP LOCATION'; // Assuming pickup locations are in the 'PICK UP LOCATION' column
        const dropOffCol = 'DROP OF LOCATION'; // Assuming drop-off locations are in the 'DROOP OF LOCATION' column

        // Get the values of the price row
        const priceRows = XLSX.utils.sheet_to_json(sheet, { header: 0 }).filter(
          (row) => row[pickupCol] === pickupLocation && row[dropOffCol] === dropOffLocation
        );

        //console.log(priceRows)

        if (priceRows.length === 0) {
          //console.log('No prices found for the given locations.');
          return;
        }

        // Take the first matching row (you might need to adjust this based on your specific use case)
        const priceRow = priceRows[0];

        // Set prices for each car segment using state-setting functions
        setPrivateSedanPrice(priceRow['PRIVATE SEDAN'] || 0);
        setPrivateSUVPrice(priceRow['PRIVATE SUV'] || 0);
        setPrivateLuxurySedanPrice(priceRow['PRIVATE LUXURY SEDAN'] || 0);
        setPrivateMinivanPrice(priceRow['PRIVATE MINI VAN'] || 0);
        setPrivateLuxuryBlackSUVPrice(priceRow['PRIVATE LUXURY BLACK SUV'] || 0);

        // Check if the pickup and drop-off locations match
        //console.log('Prices set successfully.');

  };
  const handleReturnCarDetails = () => {
        // Check if the workbook is available
        if (!workbook) {
          //console.error('Workbook not available. Make sure to fetch location names first.');
          return;
        }

        // Choose the sheet
        const sheet = workbook.Sheets['SHEET1'];

        // Find the row corresponding to the pickup and drop-off locations
        const pickupCol = 'PICK UP LOCATION'; // Assuming pickup locations are in the 'PICK UP LOCATION' column
        const dropOffCol = 'DROP OF LOCATION'; // Assuming drop-off locations are in the 'DROOP OF LOCATION' column

        // Get the values of the price row
        const priceRows = XLSX.utils.sheet_to_json(sheet, { header: 0 }).filter(
          (row) => row[pickupCol] === returnPickupLocation && row[dropOffCol] === returnDropOffLocation
        );

        //console.log(priceRows)

        if (priceRows.length === 0) {
          //console.log('No prices found for the given locations.');
          return;
        }

        // Take the first matching row (you might need to adjust this based on your specific use case)
        const priceRow = priceRows[0];

        // Set prices for each car segment using state-setting functions
        setReturnPrivateSedanPrice(priceRow['PRIVATE SEDAN'] || 0);
        setReturnPrivateSUVPrice(priceRow['PRIVATE SUV'] || 0);
        setReturnPrivateLuxurySedanPrice(priceRow['PRIVATE LUXURY SEDAN'] || 0);
        setReturnPrivateMinivanPrice(priceRow['PRIVATE MINI VAN'] || 0);
        setReturnPrivateLuxuryBlackSUVPrice(priceRow['PRIVATE LUXURY BLACK SUV'] || 0);

        // Check if the pickup and drop-off locations match
        //console.log('Return prices set successfully.');
  };


  // Function to handle the "Next" button click on the first page
  const handleNextToCar = () => {
    handleCarDetails();
    if (isReturn){
      handleReturnCarDetails();
    }
    setShowSuccessPage(false);
    const isValid = validatePage1();
    if (isValid) {
      setCurrentPage(2)
      setShowSecondPage(true);
    } else {
      // Scroll to the error section and set focus
      const errorSection = document.getElementById("initial-error-section");
      if (errorSection) {
        errorSection.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  // Function to validate the fields on the second page
  const validatePage2 = () => {
    // Validate car segment
    const errors = {};

    if (selectedVehicle === '') {
      errors.selectedVehicle = 'Please select a transfer type'
    }

    if (isReturn && returnSelectedVehicle === '') {
      errors.returnSelectedVehicle = 'Please select a return transfer type'
    }

    setValidationErrorsCar(errors);

    // Check if there are any errors
    return Object.keys(errors).length === 0;
  };

  // Function to handle the "Next" button click on the second page
  const handleNextPage2 = () => {
    
    const isValid = validatePage2();
    if (isValid) {
      setCurrentPage(3);
      setShowThirdPage(true);
    } else {
      // Scroll to the error section and set focus
      const errorSection = document.getElementById("car-error-section");
      if (errorSection) {
        errorSection.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    }
  };

  // Scroll to the page 
  useEffect(() => {
     if (currentPage === 1) {
      
      document.getElementById('booking-form').scrollIntoView({ behavior: 'smooth' });
    } 
    if (currentPage === 2) {
      
      document.getElementById('page2').scrollIntoView({ behavior: 'smooth' });
    } else if (currentPage === 3) {
      
      document.getElementById('page3').scrollIntoView({ behavior: 'smooth' });
    } else if (currentPage === 4) {
      
      document.getElementById('page4').scrollIntoView({ behavior: 'smooth' });
    } 

  }, [currentPage]);

  useEffect(() => {
    if(showSuccessPage)
      document.getElementById('page5').scrollIntoView({ behavior: 'smooth' });
  }, [showSuccessPage]);

  useEffect(() => {
    const returnSection = document.getElementById('car-page-return');
    const carPageButtons = document.getElementById('car-page-buttons');


    if (selectedVehicle && returnSection && returnSection.style.display !== 'none')
      returnSection.scrollIntoView({ behavior: 'smooth', block: "nearest" });
    else if (selectedVehicle) {
      carPageButtons.scrollIntoView({ behavior: 'smooth', block: "nearest" });
    }
  }, [selectedVehicle]);

  useEffect(() => {
    if(returnSelectedVehicle)
      document.getElementById('car-page-buttons').scrollIntoView({ behavior: 'smooth', block: "nearest" });
  }, [returnSelectedVehicle]);



  const generateExtraItem = (title, description, price, getter, setter) => (
  <tr class="extras-table-row">
    <td data-label="Extra item type">
      <div class="extras-div-14">{title}</div>
      <div class="extras-div-15">{description}</div>
    </td>
    <td data-label="Price" class="extras-div-16">{price}</td>
    <td data-label="Quantity" class="extras-div-16">
      <div class="extras-div-18">
        <select class="extras-div-19" value={getter} onChange={(e) => setter(e.target.value)} className="form-control">
          {[0, 1, 2, 3, 4, 5].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </td>
  </tr>
);


  return (
    <div >
          {/* page1 content */}
          <div class="div-30" id='booking-form'>
    <div class="div-31">
      <div class="div-32">
        <div class="column-4">
          <div class="div-33">
            <div class="div-34">PICK UP DATE AND TIME</div>
            <DatePicker selected={pickupDate} onChange={(date) => setPickupDate(date)} showTimeSelect dateFormat="Pp" 
            className={`form-control date-picker ${validationErrorsInitial.pickupDate ? 'validation-error-field' : ''}`} 
            wrapperClassName="date-picker" placeholderText={pickupDate ? undefined : 'Select Pick Up Date and Time'}/>
          </div>
            {validationErrorsInitial.pickupDate && <p className="validation-error">{validationErrorsInitial.pickupDate}</p>}
        </div>
        <div class="column-4">
          <div class="div-33">
            <div class="div-37">PICK UP LOCATION</div>
            <div class="div-38">
              <select value={pickupLocation} onChange={(e) => setPickupLocation(e.target.value)} 
              className={`form-control ${validationErrorsInitial.pickupLocation ? 'validation-error-field' : ''}`}
              >
                    <option value="" disabled hidden>Select A Pick Up Location</option>
                     <optgroup label="Airports">
                      {airports.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Other Locations">
                      {otherLocations.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </optgroup>
                </select>
            </div>
          </div>
          {validationErrorsInitial.pickupLocation && <p className="validation-error">{validationErrorsInitial.pickupLocation}</p>}
        </div>
        <div class="column-4">
          <div class="div-33">
            <div class="div-41">DROP OFF LOCATION</div>
            <div class="div-42">
              <select value={dropOffLocation} onChange={(e) => setDropOffLocation(e.target.value)} 
              className={`form-control ${validationErrorsInitial.dropOffLocation ? 'validation-error-field' : ''}`}
              placeholder='Select A Location'>
                    <option value="" disabled hidden>Select A Drop Off Location</option>
                    <optgroup label="Airports">
                      {airports.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Other Locations">
                      {otherLocations.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </optgroup>
                  </select>
            </div>
          </div>
          {validationErrorsInitial.dropOffLocation && <p className="validation-error">{validationErrorsInitial.dropOffLocation}</p>}
        </div>
        <div class="column-4">
          <div class="div-33">
            <div class="div-45">NUMBER OF PEOPLE</div>
            <input type="number" value={peopleCount} onChange={(e) => setPeopleCount(e.target.value)} 
            className={`form-control ${validationErrorsInitial.peopleCount ? 'validation-error-field' : ''}`}
             />
          </div>
          {validationErrorsInitial.peopleCount && <p className="validation-error">{validationErrorsInitial.peopleCount}</p>}
        </div>
      </div>
    </div>
    <div class="div-47">
      <div class="div-48">
          <input type="radio" name="tripType" checked={!isReturn} onChange={() => setIsReturn(false)} /> 
        <div class="div-49">ONE WAY</div>
      </div>
      <div class="div-50">
          <input type="radio" name="tripType" checked={isReturn} onChange={() => setIsReturn(true)} />
        <div class="div-52">RETURN</div>
      </div>
    </div>
    <div class="div-32">
    {isReturn && (
      <div class="column-4">
          <div class="div-33">
            <div class="div-34">RETURN DATE AND TIME</div>
            <DatePicker selected={returnDate} onChange={(date) => setReturnDate(date)} showTimeSelect dateFormat="Pp" 
            className={`form-control date-picker ${validationErrorsInitial.returnDate ? 'validation-error-field' : ''}`}
            wrapperClassName="date-picker" placeholderText={returnDate ? undefined : 'Select Return Date and Time'}/>
          </div>
          {validationErrorsInitial.returnDate && <p className="validation-error">{validationErrorsInitial.returnDate}</p>}
        </div>
    )}

    {isReturn && (
      <div class="column-4">
          <div class="div-33">
            <div class="div-37">RETURN PICK UP LOCATION</div>
            <div class="div-38">
              <select value={returnPickupLocation} onChange={(e) => setReturnPickupLocation(e.target.value)} 
              className={`form-control ${validationErrorsInitial.returnPickupLocation ? 'validation-error-field' : ''}`}
               placeholder='Select A Location'>
                    <option value="" disabled hidden>Select A Pick Up Location</option>
                    <optgroup label="Airports">
                      {airports.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Other Locations">
                      {otherLocations.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </optgroup>
                  </select>
            </div>
          </div>
          {validationErrorsInitial.returnPickupLocation && <p className="validation-error">{validationErrorsInitial.returnPickupLocation}</p>}
        </div>
    )}

    {isReturn && (
      <div class="column-4">
          <div class="div-33">
            <div class="div-41">RETURN DROP OFF LOCATION</div>
            <div class="div-42">
              <select value={returnDropOffLocation} onChange={(e) => setReturnDropOffLocation(e.target.value)} 
              className={`form-control ${validationErrorsInitial.returnDropOffLocation ? 'validation-error-field' : ''}`}
               placeholder='Select A Location'>
                    <option value="" disabled hidden>Select A Drop Off Location</option>
                    <optgroup label="Airports">
                      {airports.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label="Other Locations">
                      {otherLocations.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </optgroup>
                  </select>
            </div>
          </div>
          {validationErrorsInitial.returnDropOffLocation && <p className="validation-error">{validationErrorsInitial.returnDropOffLocation}</p>}
        </div>
    )}

    {isReturn && (
      <div class="column-4">
          <div class="div-44">
            <div class="div-45">NUMBER OF PEOPLE</div>
            <input type="number" value={returnPeopleCount} onChange={(e) => setReturnPeopleCount(e.target.value)} 
            className={`form-control ${validationErrorsInitial.returnPeopleCount ? 'validation-error-field' : ''}`}
             />
          </div>
          {validationErrorsInitial.returnPeopleCount && <p className="validation-error">{validationErrorsInitial.returnPeopleCount}</p>}
        </div>
    )}
    </div>
    {/* {currentPage === 1 && ( */}
    <div class="div-page1-buttons">
      <button class="div-53" id='btn-page1-next' onClick={handleNextToCar}>PROCEED</button>
      <Link to="/contact" class="div-53">
            CUSTOM TRIP
      </Link>
      
    </div>
          </div>
          {promoCode && promoCode.isActive && ( // Check if promo code exists and is active 
      
      <div className="voucher">
      <div className="voucher-header">Unlock Savings and Convenience!</div>
      <div className="voucher-code">{promoCode.code}</div>
      <div className="voucher-discount">{promoCode.percentage}% OFF</div>
      <div className="voucher-details">
        <p>
          Get up to <strong>${promoCode.maxDiscountAmount}</strong> off your bookings over <strong>${promoCode.minPaymentAmount}</strong>—our way of saying thank you for choosing us.{" "}
          {promoCode.untilDate ? (
            <>
              Hurry, this exclusive offer expires on <strong>{format(promoCode.untilDate.toDate(), 'MM/dd/yyyy')}</strong>! Book now and save!
            </>
          ) : (
            <span>No expiration date specified</span>
          )}
        </p>

          </div>
    </div>
    )}

          {showSecondPage && (
          // Second page fields
          <div class="car-div" id="page2">
           
  <div class="car-div-2">Select transfer type for your DEPARTURE</div>



  <div class="car-div-3">
    <div id='car-error-section'>
      {validationErrorsCar.selectedVehicle && <p  style={{ textAlign: 'center' }} className="validation-error-payment">{validationErrorsCar.selectedVehicle}</p>}
      {validationErrorsCar.returnSelectedVehicle && <p  style={{ textAlign: 'center' }} className="validation-error-payment">{validationErrorsCar.returnSelectedVehicle}</p>}
    </div>
    {carSegments
      // Filter out segments that don't meet the criteria
      .filter(segment => segment.people >= peopleCount && segment.isActive && getSegmentPrice(segment.title) !== "NOT AVAILABLE")
      // Sort segments by price
      .sort((a, b) => {
        const priceA = parseFloat(getSegmentPrice(a.title));
        const priceB = parseFloat(getSegmentPrice(b.title));
        return priceA - priceB;
      })
      // Map over sorted segments and render CarSegment components
      .map((segment, index) => (
        <CarSegment
          key={index}
          imageUrl={segment.imageUrl}
          title={segment.title}
          people={segment.people}
          suitcases={segment.suitcases}
          estimatedTime={segment.estimatedTime}
          price={`CAD ${getSegmentPrice(segment.title)}`} // Prepend "CAD" to the price
          onSelect={() => handleVehicleSelection(segment.title)}
          isSelected={selectedVehicle === segment.title}
        />
      ))
    }


  {peopleCount == 7 && privateLuxuryBlackSUVPrice === "NOT AVAILABLE" && (
    <p style={{ textAlign: 'center' }}>
      There are no transfer types available with these parameters. Please try different parameters above.
    </p>
  )}

  {peopleCount > 7 && (
    <p style={{ textAlign: 'center' }}>
      There are no transfer types available with these parameters. Please try different parameters above.
    </p>
  )}
</div>

          

          {isReturn && (
  <div class="car-div-return" id='car-page-return'>
    <div class="car-div-2">Select transfer type for your RETURN</div>
    <div class="car-div-3">
      {carSegments
        // Filter out segments that don't meet the criteria
        .filter(segment => segment.people >= returnPeopleCount && segment.isActive && getReturnSegmentPrice(segment.title) !== "NOT AVAILABLE")
        // Sort segments by price
        .sort((a, b) => {
          const priceA = parseFloat(getReturnSegmentPrice(a.title));
          const priceB = parseFloat(getReturnSegmentPrice(b.title));
          return priceA - priceB;
        })
        // Map over sorted segments and render CarSegment components
        .map((segment, index) => (
          <CarSegment
            key={index}
            imageUrl={segment.imageUrl}
            title={segment.title}
            people={segment.people}
            suitcases={segment.suitcases}
            estimatedTime={segment.estimatedTime}
            price={`CAD ${getReturnSegmentPrice(segment.title)}`} // Prepend "CAD" to the price
            onSelect={() => handleReturnVehicleSelection(segment.title)}
            isSelected={returnSelectedVehicle === segment.title}
          />
        ))
      }

      {returnPeopleCount == 7 && returnPrivateLuxuryBlackSUVPrice === "NOT AVAILABLE" && (
        <p style={{ textAlign: 'center' }}>
          There are no transfer types available with these parameters. Please try different parameters above.
        </p>
      )}

      {returnPeopleCount > 7 && (
        <p style={{ textAlign: 'center' }}>
          There are no transfer types available with these parameters. Please try different parameters above.
        </p>
      )}
    </div>
  </div>
)}

          <div className='car-div-representative'>
            *The images used for transfer types are representative
          </div>
          <div className='car-div-3-buttons' id='car-page-buttons'>
          <div class="extras-div-72">
        <button class="extras-div-73" onClick={prevPage}>
          
            Previous
        </button>
        <button class="extras-div-74" onClick={handleNextPage2}>
            Proceed to Extras
        </button>
      </div>
      
              </div>

          </div>
          
          )}



          {showThirdPage && (
          // Third page fields
          <div class="extras-div" id='page3'>
  <div class="extras-div-2">
    <div class="extras-div-3">Baggage and Extras</div>
    <div class="extras-div-4">
      Please indicate the total quantity of additional items for your transfers. 
      If you arrive with more extra items than initially specified during booking, we cannot assure their transportation. 
      In the event that we can accommodate them, an additional fee will be applied.
    </div>
    <div class="extras-div-5">
      <div class="extras-div-6">Departure extra items</div>
      <table class="extras-table">
  <thead>
    <tr>
      <th>Extra item type</th>
      <th>Price</th>
      <th>Quantity</th>
    </tr>
  </thead>
  <tbody>
    {generateExtraItem('Tip the driver', 'tips are highly appreciated', 'CAD 20', tipTheDriver, setTipTheDriver)}
    {generateExtraItem('Infant seat', 'for babies weighing up to 9 kg', 'CAD 20', infantSeat, setInfantSeat)}
    {generateExtraItem('Child seat', 'for children weighing from 15 to 36 kg', 'CAD 20', childSeat, setChildSeat)}
    {generateExtraItem('Hand baggage up to 5 kg', 'and max dimensions 40 x 22 x 55 cm', 'CAD 0', handBaggage, setHandBaggage)}
    {generateExtraItem('Pieces of baggage up to 23 kg', 'and max dimensions 53 x 31 x 75 cm', 'CAD 0', baggage, setBaggage)}
    {generateExtraItem('Additional pick up/drop off point', '', 'CAD 20', additionalPickupDropOff, setAdditionalPickupDropOff)}
  </tbody>
</table>

    </div>

    {isReturn && (
      <div class="extras-div-5">
      <div class="extras-div-6">Return extra items</div>
      <table class="extras-table">
      <thead>
        <tr>
          <th>Extra item type</th>
          <th>Price</th>
          <th>Quantity</th>
        </tr>
      </thead>
        <tbody>
        {generateExtraItem('Tip the driver', 'tips are highly appreciated', 'CAD 20', returnTipTheDriver, setReturnTipTheDriver)}
        {generateExtraItem('Infant seat', 'for babies weighing up to 9 kg', 'CAD 20', returnInfantSeat, setReturnInfantSeat)}
        {generateExtraItem('Child seat', 'for children weighing from 15 to 36 kg', 'CAD 20', returnChildSeat, setReturnChildSeat)}
        {generateExtraItem('Hand baggage up to 5 kg', 'and max dimensions 40 x 22 x 55 cm', 'CAD 0', returnHandBaggage, setReturnHandBaggage)}
        {generateExtraItem('Pieces of baggage up to 23 kg', 'and max dimensions 53 x 31 x 75 cm', 'CAD 0', returnBaggage, setReturnBaggage)}
        {generateExtraItem('Additional pick up/drop off point', '', 'CAD 20', returnAdditionalPickupDropOff, setReturnAdditionalPickupDropOff)}
      </tbody>
</table>
</div>
    )}

    <div class="extras-div-72">
        <button class="extras-div-73" onClick={prevPage}>
          
            Previous
        </button>
        <button class="extras-div-74" onClick={handleNextToConfirmation}>
            Proceed to Checkout
        </button>
      </div>
  </div>
</div>
          )}

          {showFourthPage && (
          // Fourth page (Confirmation)
          <div class="payment-div" id='page4'>
  <div >
    <table className="responsive-table">
        <tbody>
          <tr>
            <td>
              <label>Email</label>
              <input
                type="email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                className={`form-control ${validationErrorsPayment.userEmail ? 'validation-error-field-payment' : ''}`}
                placeholder="Enter email address"
              />
              {validationErrorsPayment.userEmail && <p className="validation-error-payment">{validationErrorsPayment.userEmail}</p>}
            </td>
          </tr>
          <tr>
            <td>
              <label>Phone</label>
              <PhoneInput
                country="CA"
                value={userPhone}
                onChange={setUserPhone}
                className={`form-control ${validationErrorsPayment.userPhone ? 'validation-error-field-payment' : ''}`}
                placeholder='Enter phone number'
              />
            {validationErrorsPayment.userPhone && <p className="validation-error-payment">{validationErrorsPayment.userPhone}</p>}
            </td>
          </tr>
          <tr>
            <td>
              <label>Address Details for Pickup/Drop-off</label>
            </td>
          </tr>
          <tr>
            <td>
              <label style={{fontStyle: 'italic'}}>Address Line 1</label>
              <input
                type="text"
                value={userAddress.line1}
                onChange={(e) => setUserAddress({ ...userAddress, line1: e.target.value })}
                className={`form-control ${validationErrorsPayment.userAddressLine1 ? 'validation-error-field-payment' : ''}`}
                placeholder="Enter address line 1"
              />
            {validationErrorsPayment.userAddressLine1 && <p className="validation-error-payment">{validationErrorsPayment.userAddressLine1}</p>}
            </td>
            </tr>
            
          <tr>
            <td>
              <label style={{fontStyle: 'italic'}}>Address Line 2</label>
              <input
                type="text"
                value={userAddress.line2}
                onChange={(e) => setUserAddress({ ...userAddress, line2: e.target.value })}
                className={`form-control ${validationErrorsPayment.userAddressLine2 ? 'validation-error-field-payment' : ''}`}         
                placeholder="Enter address line 2"
              />
            {validationErrorsPayment.userAddressLine2 && <p className="validation-error-payment">{validationErrorsPayment.userAddressLine2}</p>}
            </td>
            </tr>
            
          <tr>
            <td>
              <label style={{ fontStyle: 'italic' }}>City</label>
              <input
                type="text"
                value={userAddress.city}
                readOnly
                className={`form-control ${
                  validationErrorsPayment.userAddressCity ? 'validation-error-field-payment' : ''
                }`}
                placeholder="Enter city"
              />
              {validationErrorsPayment.userAddressCity && (
                <p className="validation-error-payment">{validationErrorsPayment.userAddressCity}</p>
              )}
            </td>

          </tr>
          <tr>
            <td>
              <label style={{fontStyle: 'italic'}}>Province</label>
              <select
                value={userAddress.province}
                onChange={(e) => setUserAddress({ ...userAddress, province: e.target.value })}
                className={`form-control ${validationErrorsPayment.userAddressProvince ? 'validation-error-field-payment' : ''}`} 
              >
                <option value="Ontario">Ontario</option>
                {/* Add other provinces as needed */}
              </select>
            {validationErrorsPayment.userAddressProvince && <p className="validation-error-payment">{validationErrorsPayment.userAddressProvince}</p>}
            </td>
          </tr>
          <tr>
            <td>
              <label style={{fontStyle: 'italic'}}>Postal Code</label>
              <input
                type="text"
                name="postalCode"
                value={userAddress.postalCode}
                onChange={(e) => setUserAddress({ ...userAddress, postalCode: e.target.value })}
                className={`form-control ${validationErrorsPayment.userAddressPostalCode ? 'validation-error-field-payment' : ''}`} 
                placeholder='Enter postal code'
              />
            {validationErrorsPayment.userAddressPostalCode && <p className="validation-error-payment">{validationErrorsPayment.userAddressPostalCode}</p>}
            </td>
          </tr>
        </tbody>
      </table>
    <div class="payment-div-3">
      <div class="payment-column">
  <div class="payment-table">
  <div class="payment-row payment-header">
    <div class="payment-cell">Product</div>
    <div class="payment-cell">Subtotal</div>
  </div>
  <div class="payment-row">
    <div class="payment-cell">
      <span>
        Transfer from {confirmedBooking.pickupLocation} to {confirmedBooking.dropOffLocation} 
        <br />
        by {confirmedBooking.selectedVehicle}
         <br />
                on {confirmedBooking.pickupDate.toLocaleString()}
                <br />
                People: {confirmedBooking.peopleCount}
                <br />
                Extras:
                <br />
                {confirmedBooking.infantSeat != '0' && (
                  <span>
                    Infant Seat: {confirmedBooking.infantSeat}
                    <br />
                  </span>
                )}
                {confirmedBooking.childSeat != '0' && (
                  <span >
                    Child Seat: {confirmedBooking.childSeat}
                    <br />
                  </span>
                )}
                {confirmedBooking.handBaggage != '0' && (
                  <span >
                    Hand Baggage: {confirmedBooking.handBaggage}
                    <br />
                  </span>
                )}
                {confirmedBooking.baggage != '0' && (
                  <span >
                    Baggage: {confirmedBooking.baggage}
                    <br />
                  </span>
                )}
                {confirmedBooking.additionalPickupDropOff != '0' && (
                  <span >
                    Additional Pickup/Drop Off: {confirmedBooking.additionalPickupDropOff}
                    <br />
                  </span>
                )}
                {confirmedBooking.tipTheDriver != '' && (
                  <span >
                    Tip the Driver: ${20 * confirmedBooking.tipTheDriver}
                    <br />
                  </span>
                )}
              </span>
    </div>
    <div class="payment-cell">${calculateTotalPrice()}</div>
  </div>
  {isReturn && (
    <div class="payment-row">
      <div class="payment-cell">
        <span>
          Transfer from {confirmedBooking.returnPickupLocation} to {confirmedBooking.returnDropOffLocation} 
          <br />
          by {confirmedBooking.returnSelectedVehicle}
          <br />
                  on {confirmedBooking.returnDate.toLocaleString()}
                  <br />
                  People: {confirmedBooking.returnPeopleCount}
                  <br />
                  Extras:
                  <br />
                  {confirmedBooking.returnInfantSeat != '0' && (
                    <span>
                      Infant Seat: {confirmedBooking.returnInfantSeat}
                      <br />
                    </span>
                  )}
                  {confirmedBooking.returnChildSeat != '0' && (
                    <span >
                      Child Seat: {confirmedBooking.returnChildSeat}
                      <br />
                    </span>
                  )}
                  {confirmedBooking.returnHandBaggage != '0' && (
                    <span >
                      Hand Baggage: {confirmedBooking.returnHandBaggage}
                      <br />
                    </span>
                  )}
                  {confirmedBooking.returnBaggage != '0' && (
                    <span >
                      Baggage: {confirmedBooking.returnBaggage}
                      <br />
                    </span>
                  )}
                  {confirmedBooking.returnAdditionalPickupDropOff != '0' && (
                    <span >
                      Additional Pickup/Drop Off: {confirmedBooking.returnAdditionalPickupDropOff}
                      <br />
                    </span>
                  )}
                  {confirmedBooking.returnTipTheDriver != '' && (
                    <span >
                      Tip the Driver: ${20 * confirmedBooking.returnTipTheDriver}
                      <br />
                    </span>
                  )}
        </span>
      </div>
      <div class="payment-cell">${calculateReturnTotalPrice()}</div>
    </div>
  )}
  {promoCode.isActive && calculatePromoDeduction() != 0 && (
    <div class="payment-row">
      <div class="payment-cell">Promo Applied: {promoCode.code}, {promoCode.percentage}%, up to ${promoCode.maxDiscountAmount}</div>
      <div class="payment-cell">- ${calculatePromoDeduction()}</div>
    </div>
  )}
  
  <div class="payment-row">
    <div class="payment-cell">Total</div>
    <div class="payment-cell">${calculateTotalPrice() + calculateReturnTotalPrice() - calculatePromoDeduction()}</div>
  </div>
</div>

</div>

      <div class="payment-column-4">
        <div 
        
        className={`payment-div-28 ${validationErrorsPayment.selectedPaymentMethod ? 'validation-error-field-payment' : ''}`}         
        
        >
          <div >
            <div class="payment-div-35">
              <input
                type='radio'
                checked={selectedPaymentMethod === "etransfer"}
                onChange={() => {
                  if (validatePaymentPage()) {
                    setSelectedPaymentMethod('etransfer');
                  }
                }}
                className="payment-div-36"
              />
              <div class="payment-div-37">e-Transfer</div>
            </div>
            {selectedPaymentMethod === "etransfer" && (
              <div class="payment-div-34">
                Make your payment directly into our bank account by e-transfer
              (info@luxtransport.ca). Please use your information as the payment
              reference.
              
                
              </div>
            )}
          </div>
          <div class="payment-div-35">
              <input
                type='radio'
                checked={selectedPaymentMethod === "credit card / paypal"}
                onChange={() => {
                  if (validatePaymentPage()) {
                    setSelectedPaymentMethod("credit card / paypal");
                  }
                }}
                className="payment-div-36"
              />
              <div class="payment-div-37">Credit Card / Paypal</div>
              <img
                loading="lazy"
                src={VisaSVG}
                class="img"
              />
              <img
                loading="lazy"
                src={MasterCardSVG}
                class="img"
              />
              <img
                loading="lazy"
                src={AmericanExpressSVG}
                class="img"
              />
              <img
                loading="lazy"
                src={JCBSVG}
                class="img"
              />
            </div>
            {selectedPaymentMethod === "credit card / paypal" && (
              <div class="payment-div-34">
                <PayPalPayment totalPrice={calculateTotalPrice() + calculateReturnTotalPrice()} onPaymentSuccess={() => setPaymentSuccessful(true)} />
              </div>
            )}
          <div class="payment-div-35">
            <input
              type='radio'
              checked={selectedPaymentMethod === "cash on trip"}
              onChange={() => {
                if (validatePaymentPage()) {
                  setSelectedPaymentMethod("cash on trip");
                }
              }}
              className="payment-div-36"
            />
            <div class="payment-div-37">Cash on trip</div>
          </div>
          <div class="payment-div-41">
            Your personal data will be used to process your order, check our&nbsp;          
            <Link to="/faq">
              privacy policy.
            </Link>
            
          </div>

        </div>

      </div>
    </div>
    <div id='payment-error-section'>
          {validationErrorsPayment.selectedPaymentMethod && <p style={{ textAlign: 'center' }} className="validation-error-payment">{validationErrorsPayment.selectedPaymentMethod}</p>}
      </div>
    <div class="payment-div-72">
        <button class="extras-div-73" onClick={prevPage}>
          
            Previous
        </button>
        { !paymentSuccessful && (
            <button class="extras-div-74" onClick={handlePaymentPage}>Place order</button>
          )}
      </div>
      
      
  </div>
</div>
        )}

        {showSuccessPage && (
          <div class="success-div" id='page5'>
            <CheckCircleSharpIcon
              loading="lazy"
              class="success-img"
            />
            <div class="success-div-2">
              Your order has been processed successfully. 
            </div>
        
            <div class="success-div-3">
              A confirmation email has been sent to the email address you provided. 
              If the confirmation email is not found in your primary inbox, <b>kindly check your spam folder.</b>
              &nbsp;One of our team members will reach out to you shortly. 
              If you have any questions or need to make adjustmens to your booking, you can give us a call at <b>1 (382) 885-5588</b> or send an email to <b><a href="mailto:info@luxtransport.ca">info@luxtransport.ca</a></b>. 
              Thank you for choosing us!
            </div>
          </div>
        )}



    </div>
  );
          
};


export default BookingForm;
