import React from 'react';
import './assets/styles/Destinations.css';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import torontoImage from './assets/images/toronto.jpg';
import torontoNightImage from './assets/images/toronto_night.jpg';
import londonImage from './assets/images/london.jpg';
import hamiltonImage from './assets/images/hamilton.jpg';
import waterlooImage from './assets/images/waterloo.jpg';

const Destinations = () => {
  const isMobile = useMediaQuery({ maxWidth: 991 }); // Adjust the max width according to your design breakpoints
  const slidesToShow = isMobile ? 1 : 2;

  return (
    <div class="destinations-div">
  <div class="destinations-div-2">
    <div class="destinations-div-4">Our Destinations</div>
    <div class="destinations-div-3">
      Explore our popular destinations and airports served.
    </div>
    <div className="destinations-div-6">
   <Slider
      dots
      infinite
      speed={500}
      slidesToShow={slidesToShow}
      slidesToScroll={1}
   >
      <div className="destinations-column">
         <div className="destinations-div-8">
            <img
              loading="lazy"
              src={torontoImage}
              class="destinations-img"
            />
            <div class="destinations-div-9">
              <div class="destinations-div-10">Airport</div>
              <div class="destinations-div-11">Mississauga, Ontario</div>
            </div>
            <div class="destinations-div-12">Toronto Pearson International Airport</div>
            <div class="destinations-div-13">
              Toronto Pearson International Airport, located in Mississauga, Ontario, is Canada's busiest airport, serving as a major global hub for both domestic and international flights. With multiple terminals and a wide range of amenities, it plays a key role in facilitating air travel in the Toronto metropolitan area.
            </div>
         </div>
      </div>
      <div className="destinations-column">
         <div className="destinations-div-16">
            <img
              loading="lazy"
              src={torontoNightImage}
              class="destinations-img"
            />
            <div class="destinations-div-9">
              <div class="destinations-div-10">Airport</div>
              <div class="destinations-div-11">Toronto, Ontario</div>
            </div>
            <div class="destinations-div-20">Billy Bishop Toronto City Airport</div>
            <div class="destinations-div-21">
              Billy Bishop Toronto City Airport, nestled on Toronto Island in Lake Ontario, is a unique and convenient gateway to the heart of Toronto. Offering a serene and scenic approach, this airport is a hub for regional flights, connecting travelers to the downtown core. With modern amenities and a charming atmosphere, Billy Bishop Airport provides a seamless and enjoyable experience for both domestic and international travelers visiting Toronto.
            </div>
         </div>
      </div>
      <div className="destinations-column">
         <div className="destinations-div-16">
            <img
              loading="lazy"
              src={waterlooImage}
              class="destinations-img"
            />
            <div class="destinations-div-9">
              <div class="destinations-div-10">Airport</div>
              <div class="destinations-div-11">Breslau, Ontario</div>
            </div>
            <div class="destinations-div-20">Region of Waterloo International Airport</div>
            <div class="destinations-div-21">
              
Region of Waterloo International Airport, situated in Breslau, Ontario, is a growing airport serving the Waterloo Region. Providing domestic and seasonal international flights, it caters to the local community and contributes to regional air transportation.
            </div>
         </div>
      </div>
      <div className="destinations-column">
         <div className="destinations-div-16">
            <img
              loading="lazy"
              src={hamiltonImage}
              class="destinations-img"
            />
            <div class="destinations-div-9">
              <div class="destinations-div-10">Airport</div>
              <div class="destinations-div-11">Hamilton, Ontario</div>
            </div>
            <div class="destinations-div-20">John C. Munro Hamilton International Airport</div>
            <div class="destinations-div-21">
              John C. Munro Hamilton International Airport, situated in Hamilton, Ontario, stands as a prominent air travel hub serving the Greater Toronto and Hamilton Area. Recognized for its strategic location, this regional airport facilitates domestic and international flights, contributing significantly to Southern Ontario's air travel infrastructure. With a diverse range of services, John C. Munro Hamilton International Airport ensures a convenient and efficient travel experience for passengers exploring the vibrant surroundings of Hamilton and beyond.
            </div>
         </div>
      </div>
      <div className="destinations-column">
         <div className="destinations-div-16">
            <img
              loading="lazy"
              src={londonImage}
              class="destinations-img"
            />
            <div class="destinations-div-9">
              <div class="destinations-div-10">Airport</div>
              <div class="destinations-div-11">London, Ontario</div>
            </div>
            <div class="destinations-div-20">London International Airport</div>
            <div class="destinations-div-21">
              London International Airport, located in London, Ontario, is a key aviation facility offering efficient air travel services. Serving the city of London and its surrounding regions, this airport plays a pivotal role in connecting passengers to domestic and international destinations. With modern amenities and a commitment to passenger satisfaction, London International Airport provides a reliable gateway for travelers seeking convenient and comfortable journeys.
            </div>
         </div>
      </div>
      {/* Add more slides as needed */}
   </Slider>
</div>

    
  </div>
</div>
  );
};

export default Destinations;