import React from 'react';
import '../assets/styles/Footer.css';
import logo from "../assets/images/logo_white.png"
import WhatsAppButton from "../components/WhatsAppButton"
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div class="div-67">
    <div class="div-68">
      <div class="div-69">
        <img
          loading="lazy"
          src={logo}
          class="img-11"
        />
        <div class="div-70">
          At LUX Transport, we are committed to providing exceptional
          transportation services with a focus on customer satisfaction and
          safety. With our diverse fleet of cars and experienced drivers, we
          ensure a comfortable and reliable journey for all our passengers.
        </div>
      </div>
      <div class="div-71">
        <div class="div-72">Need Help?</div>
        <div class="div-73">
          <div class="div-74">Contact:</div>
          <div class="div-75">1 (382) 885-5589</div>
          <div class="div-76">
             <a href="mailto:info@luxtransport.ca">info@luxtransport.ca</a>
          </div>
          <div class="div-76">
            <WhatsAppButton />
          </div>
        </div>
      </div>
      <div class="div-77">
        <div class="div-78">Follow us</div>
        <div class="div-79">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7b273f09-8240-4b8d-bb4c-7ed8db2c2a66?apiKey=4a969b8594f0435493444d2b236405f7&"
            class="img-12"
          />
          <div class="div-80"><a href="https://facebook.com/luxtransportca">Facebook</a></div>
        </div>
        <div class="div-81">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4b15f74d-aec7-4c42-9cd2-a5b61b52d461?apiKey=4a969b8594f0435493444d2b236405f7&"
            class="img-13"
          />
          <div class="div-82"><a href="https://instagram.com/luxtransportca">Instagram</a></div>
        </div>
        {/* <div class="div-83">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d2abcae6-a384-42a6-94f2-b31cab0c87ff?apiKey=4a969b8594f0435493444d2b236405f7&"
            class="img-14"
          />
          <div class="div-84">Twitter</div>
        </div>
        
        <div class="div-85">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/357c380a-722a-4f32-95da-7d880e1cf620?apiKey=4a969b8594f0435493444d2b236405f7&"
            class="img-15"
          />
          <div class="div-86">LinkedIn</div>
        </div>
        <div class="div-87">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/5a8c5305-302a-4f46-bd78-83d1e77b8a30?apiKey=4a969b8594f0435493444d2b236405f7&"
            class="img-16"
          />
          <div class="div-88">Youtube</div>
        </div> */}
      </div>
    </div>
    <div class="div-89"></div>
    <div class="div-90">
      <div class="div-91">© 2023 Lux Transport. All rights reserved.
      {/* <br></br>
      Designed and Developed by Aydin Battal */}
      </div>
      <div class="div-92">
        <Link to="/faq">
          <div class="div-93">Privacy Policy</div>
        </Link>
        {/* <div class="div-94">Terms of Service</div> */}
        {/* <div class="div-95">Cookies Settings</div> */}
      </div>
    </div>
  </div>
  );
};

export default Footer;