import React, {useState} from 'react';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';

const { REACT_APP_PAYPAL_CLIENT_ID } = process.env;

const PayPalPayment = ({ totalPrice, onPaymentSuccess }) => {

  return (
    <PayPalScriptProvider options={{ 'client-id': REACT_APP_PAYPAL_CLIENT_ID, 'currency': 'CAD' }}>
      <PayPalPaymentContent totalPrice={totalPrice} onPaymentSuccess={onPaymentSuccess} />
    </PayPalScriptProvider>
  );
};

const PayPalPaymentContent = ({ totalPrice, onPaymentSuccess }) => {
  const [{ isPending, isResolved, isRejected }] = usePayPalScriptReducer();
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);

  const createOrder = (data, actions) => {
    // Create the order details
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: 'CAD',
            value: totalPrice,
          },
        },
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING',
      }
    });
  };

  const onApprove = async (data, actions) => {
    return actions.order.capture().then((details) => {
      console.log('Payment successful!', details);
      setPaymentSuccessful(true)

      // Call the onPaymentSuccess callback here to notify the parent component
      if (typeof onPaymentSuccess === 'function') {
        onPaymentSuccess();
      }
    });
  };

  return (
    <div className="paypal-payment">
      {isPending && (
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}

      {isRejected && <div className="error-message">Error loading PayPal</div>}
      {isResolved && !paymentSuccessful && (
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          // onError={(error) => console.error('PayPal Error:', error)}
        />
      )}
      {paymentSuccessful && (
        <div className="success-message">
          Payment successful! Thank you for your purchase.
        </div>
      )}
    </div>
  );
};

export default PayPalPayment;
