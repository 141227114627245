import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBb-9u1UyIBssaM7YdVG5QCAx413yGl_lM",
  authDomain: "airporttripbooking.firebaseapp.com",
  projectId: "airporttripbooking",
  storageBucket: "airporttripbooking.appspot.com",
  messagingSenderId: "1006206772613",
  appId: "1:1006206772613:web:ae9d27bdbdc72433c950ec"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);

const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    //console.log('Successfully signed in with Google:', user);
    return user;
  } catch (error) {
    //console.error('Error signing in with Google:', error);
    throw error;
  }
};

export { db, auth, signInWithGoogle, storage };
