import {useState} from 'react'
import PeopleAltSharpIcon from '@mui/icons-material/PeopleAltSharp';
import BusinessCenterSharpIcon from '@mui/icons-material/BusinessCenterSharp';

const CarSegment = ({ imageUrl, title, people, suitcases, estimatedTime, price, onSelect, isSelected }) => {

  const [buttonClicked, setButtonClicked] = useState(false);

  const handleButtonClick = () => {
    setButtonClicked(true);
    onSelect(); // Call the onSelect prop to handle the selection
  };

  return (
    <div class="car-div-4">
      <div class="car-div-7">{title}</div>
      <div class="car-div-5">
        <div class="car-column">
          <img
            loading="lazy"
            srcset={imageUrl}
            class="car-img"
          />
        </div>
        <div class="car-column-2">
          <div class="car-div-6">
            
            <div class="car-div-8">
              <div class="car-div-9">
                <div class="car-column-4">
                  <div class="car-div-10">
                    <PeopleAltSharpIcon class="car-div-11"/>
                    <div class="car-div-12">
                      <span
                        
                      >
                        Max
                      </span>
                      <span
                        
                      >
                        &nbsp;<b>{people} people</b>
                        <br />
                      </span>
                      <span
                        
                      >
                        per vehicle
                      </span>
                    </div>
                  </div>
                </div>
                <div class="car-column-4">
                  <div class="car-div-10">
                    <BusinessCenterSharpIcon class="car-div-11"/>
                    <div class="car-div-12">
                      <span
                        
                      >
                        Max
                      </span>
                      <span
                        
                      >
                        &nbsp;<b>{suitcases} suitcases</b>
                        <br />
                      </span>
                      <span
                        
                      >
                        per vehicle
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div class="car-column-5">
                  <div class="car-div-10">
                    <div class="car-div-17"></div>
                    <div class="car-div-18">
                      <span
                        
                      >
                        Estimated time
                        <br />
                      </span>
                      <span
                        
                      >
                        {estimatedTime}
                      </span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div class="car-column-6">
          <div class="car-div-19">
            <div class="car-div-20">{price}</div>
            <div class="car-div-21">per vehicle</div>
            <button className={`car-div-22 ${isSelected ? 'selected-button' : ''}`} onClick={handleButtonClick}>
              Select
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarSegment;