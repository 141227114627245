import React, { useEffect } from 'react';
import './assets/styles/FAQ.css';
import './assets/styles/Destinations.css';
import {
  privateSedanImage,
  privateSUVImage,
  privateLuxurySedanImage,
  privateMinivanImage,
  privateLuxuryBlackSUVImage
} from './utils/ImageFetcher';
import faqImage from './assets/images/faq_image.jpg'
import { Link } from 'react-router-dom';
import FAQItem from './components/FAQItem';

import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FAQ = () => {

  const faqData = [
    {
      question: 'How do I make a booking?',
      answer: `You can make a booking by navigating to our home page and clicking the "Make A Booking" button or scrolling down to the booking form manually.`,
    },
    {
      question: 'How can I contact you?',
      answer: `You can contact us through our dedicated contact form on our contact us page, by sending an email to info@luxtransport.ca, or by giving us a call at 1 (382) 885-5589. We're here to help!`,
    },

    {
      question: `What's your privacy policy?`,
      answer: `

        We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard the data you provide to us.

        1. Collection of Personal Information

        We may collect certain personal information, such as your email address, phone number, and address, solely for the purpose of providing you with a seamless and hassle-free transportation service. This information is essential for facilitating the booking process, ensuring accurate communication, and delivering our services effectively.

        2. Use of Personal Information

        Your personal information will be used exclusively for the following purposes:

        To process and confirm your transportation bookings.
        To communicate with you regarding your bookings, updates, and important service-related information.
        To enhance and personalize your experience with our transportation services.
        We do not sell, share, or disclose your personal information to third parties for any unrelated purposes without your explicit consent.

        3. Security Measures

        We employ industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. Your data is treated with the utmost confidentiality and is accessible only to authorized personnel who require it for the performance of their duties.

        4. Consent

        By using our transportation services and providing us with your personal information, you consent to the collection, use, and disclosure of that information in accordance with this Privacy Policy.

        5. Updates to Privacy Policy

        We reserve the right to update and modify this Privacy Policy periodically. Any changes will be reflected on this page, and it is your responsibility to review this policy regularly for updates.

        If you have any concerns or questions regarding our Privacy Policy, please contact us at 1 (382) 885-5589 or info@luxtransport.ca.

        Thank you for choosing our transportation service.`,
    },
    // Add more FAQ items as needed
  ];

  const isMobile = useMediaQuery({ maxWidth: 991 }); // Adjust the max width according to your design breakpoints
  const slidesToShow = isMobile ? 1 : 3;

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div class="faq-div" id='faq'>
  <div class="faq-div-2">
    <div class="faq-div-3">
      <div class="faq-div-4">
        <div class="faq-column">
          <div class="faq-div-5">
            <div class="faq-div-6">Frequently Asked Questions</div>
            <div class="faq-div-7">
              Find answers to common questions about our booking, services,
              pricing, and policies.
            </div>
          </div>
        </div>
        <div class="faq-column">
          <div class="faq-div-9">
            {faqData.map((faq, index) => (
        <FAQItem key={index} {...faq} />
      ))}
            
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="destinations-div">
  <div class="destinations-div-2">
    <div class="destinations-div-4">Car Fleet Gallery</div>
    <div class="destinations-div-3">
      Browse our stunning car fleet collection.
    </div>
    <div className="destinations-div-6">
   <Slider
      dots
      infinite
      speed={500}
      slidesToShow={slidesToShow}
      slidesToScroll={1}
   >
      <div className="destinations-column">
         <div className="destinations-div-8">
            <img
              loading="lazy"
              src={privateSedanImage}
              class="faq-img-2"
            />
            <div class="destinations-div-9">
              <div class="destinations-div-10">3 passengers</div>
              <div class="destinations-div-11">Affordable, Convenient</div>
            </div>
            <div class="destinations-div-12">Private Sedan</div>
            <div class="destinations-div-13">
              Whether you're headed to or from the airport, the Private Sedan remains the optimal selection for affordable and efficient transportation. Our goal is to extend this economically priced chauffeur-driven airport service to diverse destinations, allowing customers to experience quality rides at a budget-friendly rate. Accommodating up to 3 passengers and 3 medium-sized bags, the Private Sedan embodies a perfect blend of affordability and convenience.
            </div>
         </div>
      </div>
      <div className="destinations-column">
         <div className="destinations-div-16">
            <img
              loading="lazy"
              src={privateSUVImage}
              class="faq-img-2"
            />
            <div class="destinations-div-9">
              <div class="destinations-div-10">4 passengers</div>
              <div class="destinations-div-11">More luggage capacity</div>
            </div>
            <div class="destinations-div-20">Private SUV</div>
            <div class="destinations-div-21">
               Whether you prioritize traveling in elegance or require ample luggage capacity, our fleet of unmarked SUVs delivers the perfect solution for any occasion.
               With seating for up to 4 passengers and accommodation for 4 medium-sized bags, our Private SUVs exemplify a perfect blend of sophistication, comfort, and practicality.
            </div>
         </div>
      </div>
      <div className="destinations-column">
         <div className="destinations-div-16">
            <img
              loading="lazy"
              src={privateLuxurySedanImage}
              class="faq-img-2"
            />
            <div class="destinations-div-9">
              <div class="destinations-div-10">3 passengers</div>
              <div class="destinations-div-11">Luxury, Comfortable</div>
            </div>
            <div class="destinations-div-20">Private Luxury Sedan</div>
            <div class="destinations-div-21">
               Tailored for seamless airport transfers, this segment redefines comfort and style as you navigate Toronto with one of our experienced and professional chauffeurs at the helm.
               With seating for up to 3 passengers and accommodation for 4 medium-sized bags, our Private Luxury Sedans redefine chauffeur-driven elegance and refinement.
            </div>
         </div>
      </div>
      <div className="destinations-column">
         <div className="destinations-div-16">
            <img
              loading="lazy"
              src={privateMinivanImage}
              class="faq-img-2"
            />
            <div class="destinations-div-9">
              <div class="destinations-div-10">6 passengers</div>
              <div class="destinations-div-11">Luxury, More Overall Capacity</div>
            </div>
            <div class="destinations-div-20">Private Minivan</div>
            <div class="destinations-div-21">
              Perfect for families or groups, the minivan offers not only comfortable seating but also entertainment options like watching a movie, making longer journeys enjoyable for all. Embrace the perfect blend of space, convenience, and style with our Private Minivans, accommodating up to 6 passengers and 6 medium-sized bags for a seamless travel experience.
            </div>
         </div>
      </div>

      <div className="destinations-column">
         <div className="destinations-div-16">
            <img
              loading="lazy"
              src={privateLuxuryBlackSUVImage}
              class="faq-img-2"
            />
            <div class="destinations-div-9">
              <div class="destinations-div-10">7 passengers</div>
              <div class="destinations-div-11">Luxury, More Overall Capacity</div>
            </div>
            <div class="destinations-div-20">Private Luxury Black SUV</div>
            <div class="destinations-div-21">
              Tailored for elegance and sophistication, our Private Luxury Black SUVs redefine travel in style. With plush seating for up to 7 passengers and ample space for 6 medium-sized bags, these luxury SUVs elevate your journey to a new level. Embrace comfort, convenience, and a touch of extravagance as you embark on your travels with our exclusive Private Luxury Black SUVs.
            </div>
         </div>
      </div>
      
      {/* Add more slides as needed */}
   </Slider>
</div>

    
  </div>
</div>
  <div class="faq-div-25">
    <img
      loading="lazy"
      src={faqImage}
      class="faq-img-6"
    />
    <div class="faq-div-26">Frequently Asked Questions (FAQ)</div>
    <div class="faq-div-27">
      If you have any questions or need further assistance, please don't
      hesitate to contact us.
    </div>
    <div class="faq-div-28">
        <Link to="/contact">
            <button class="faq-div-29">Contact</button>
        </Link>
    </div>
  </div>
</div>
  );
};

export default FAQ;