import React, { useState } from 'react';
import './assets/styles/Contact.css';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from './firebase';
import MapImage from './assets/images/map.png';
import { sendEmailContactForm, getAdminEmail } from './services/EmailService';


const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
   const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

   const handleSubmit = async () => {
      const contactFormsCollection  = collection(db, 'contactForms')

      if(!name || !email || !message){
        setErrorMessage('Please make sure all the fields above are filled');
      } else{
        try {
          // Add the form data to the 'contactForms' collection in Firestore
          await addDoc(contactFormsCollection, {
            name,
            email,
            message,
            timestamp: serverTimestamp(),
          });

          const adminEmail = await getAdminEmail();
          sendEmailContactForm({
            name,
            email,
            message,
          }, adminEmail);

          // Clear the form after submission
          setName('');
          setEmail('');
          setMessage('');
        // Set success message
          setSuccessMessage('Form submitted successfully!');
          setErrorMessage(''); // Clear any previous error message
        } catch (error) {
          //console.error('Error submitting form:', error);

          // Set error message
          setErrorMessage('Error submitting form. Please try again.');
          setSuccessMessage(''); // Clear any previous success message
        }
      };

    
  };

  return (
    <div class="contact-div">
  <div class="contact-div-2">
    <div class="contact-div-4">Contact Us</div>
    <div class="contact-div-5">
      Feel free to use the options below to contact us.
      <br/>
      Our team is readily available during regular hours to provide assistance.
    </div>
    <div class="contact-div-6">
      <div class="contact-div-7">
        <div class="contact-column">
          <div class="contact-div-8">
            <img
              
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/05ef35d4-a9aa-4dde-a30e-dc5896ff0ac2?apiKey=4a969b8594f0435493444d2b236405f7&"
              class="contact-img"
            />
            <div class="contact-div-9">Email</div>
            <div class="contact-div-10">Send us an email at:</div>
            <div class="contact-div-11">
               <a href="mailto:info@luxtransport.ca">info@luxtransport.ca</a>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e381922e-ff23-4430-aecc-0c567e6ff675?apiKey=4a969b8594f0435493444d2b236405f7&"
              class="contact-img-2"
            />
            <div class="contact-div-12">Phone</div>
            <div class="contact-div-13">Give us a call at:</div>
            <div class="contact-div-14">1 (382) 885-5589</div>
          </div>
        </div>
        <div class="contact-column-2">
          <img
            loading="lazy"
            src={MapImage}
            class="contact-img-3"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="contact-div-15">
    <div class="contact-div-16">
      <div class="contact-div-18">Get in Touch</div>
      <div class="contact-div-19">
        Have a question? Need assistance? We're here to help!
      </div>
      <div className="contact-div-30">
      <div>
        <div className="contact-div-20">Name</div>
        <input type="text" className="contact-div-21" value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter name'/>
      </div>
      <div>
        <div className="contact-div-22">Email</div>
        <input type="email" className="contact-div-23" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter email' />
      </div>
      <div>
        <div className="contact-div-24">Message</div>
        <input type="text" className="contact-div-25" value={message} onChange={(e) => setMessage(e.target.value)} placeholder='For custom trips please include details such as pick up date/time, number of people, pickup/dropoff location, and any additional information' />
      </div>
      <div className="contact-div-26"></div>
      <button className="contact-div-29" onClick={handleSubmit}>
        Submit
      </button>
      {successMessage && <div style={{ textAlign: 'center' }}>{successMessage}</div>}
      {errorMessage && <div style={{ textAlign: 'center' }}>{errorMessage}</div>}
    </div>
    </div>
  </div>

</div>
  );
};

export default Contact;