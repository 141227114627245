import React, { useState, useEffect } from 'react';
import '../assets/styles/Navbar.css'; 
import logo from "../assets/images/logo.png"
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="div-2">
        <div className="div-3">
          <Link to="/">
            <img
              loading="lazy"
              src={logo}
              className="img-logo"
              alt="Logo"
            />
          </Link>
          <div className="div-4">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            {!isMobile && (

              <div className="collapse navbar-collapse" id="navbarNav">
                <div className="navbar-nav">
                  <Link className="nav-link" to="/">Home</Link>
                  <Link className="nav-link" to="/about">About Us</Link>
                  <Link className="nav-link" to="/faq">FAQ</Link>
                  <Link className="nav-link" to="/contact">Contact Us</Link>
                  <Link className="nav-link" to="/destinations">Destinations</Link>
                </div>
              </div>

            )}
          </div>
        </div>
      </div>
    </nav>
    {isMobile && (

              <div className="collapse navbar-collapse" id="navbarNav">
                <div className="navbar-nav">
                  <Link className="nav-link" to="/">Home</Link>
                  <Link className="nav-link" to="/about">About Us</Link>
                  <Link className="nav-link" to="/faq">FAQ</Link>
                  <Link className="nav-link" to="/contact">Contact Us</Link>
                  <Link className="nav-link" to="/destinations">Destinations</Link>
                </div>
              </div>

            )}
            </div>
  );
};

export default Navbar;
