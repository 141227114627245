import React from 'react';
import './assets/styles/About.css';
import AboutImage from './assets/images/about_image.png';

const About = () => {
  return (
    <div class="about-div">
  <div class="about-div-2">
    <div class="about-div-3">
      <div class="about-column-2">
        <img
          loading="lazy"
          src={AboutImage}
          class="about-img-2"
        />
      </div>
      <div class="about-column">
        <div class="about-div-4">
          {/* <img
            loading="lazy"
            src={"https://cdn.builder.io/api/v1/image/assets/TEMP/c7911a65-7770-49d1-9809-7749217b0372?apiKey=4a969b8594f0435493444d2b236405f7&"}
            class="about-img"
          /> */}
          <div class="about-div-5">
            Our Journey: Connecting People, One Ride at a Time
          </div>
          <div class="about-div-6">
            At LUX Transport, we are committed to providing exceptional
            transportation services with a focus on customer satisfaction and
            safety. With our diverse fleet of cars and experienced drivers, we
            ensure a comfortable and reliable journey for all our passengers. 
            <br/>
            <br/>
            Our goal is to offer executive-class services at the most competitive rates, covering the vast landscape of the Waterloo Region. 
            At LUX Transport, we redefine travel, ensuring your journey is not only comfortable and reliable but also marked by a touch of luxury. 
            From Kitchener to the broader expanse of Ontario, trust us for unparalleled service excellence in ground transportation.
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
  );
};

export default About;