import axios from 'axios';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

const elasticEmailApiKey = '5EA80C27D42393B17F5305302D5E3E05B1C1A1C35FD6E119F3849A9BA3231C8E0564BD8C0ECB2A415F76224345473786';


const sendEmail = async (bookingData, adminEmail) => {
  const googleCalendarLink = generateGoogleCalendarLink(bookingData);
  const logoUrl = 'https://firebasestorage.googleapis.com/v0/b/airporttripbooking.appspot.com/o/logo.png?alt=media&token=0b8ab079-9a0b-4b2a-9d2f-3b9a05ab62c1';

  const emailData = {
    //todo: update the from email and name
    from: 'info@luxtransport.ca',
    fromName: 'Lux Transport',
    subject: 'New Booking',
    to: `${adminEmail};${bookingData.userEmail}`, 
    body: `
      <!DOCTYPE html>
      <html>
      <head>
        <style>
          /* Add your email styling here */
          body {
            font-family: Arial, sans-serif;
          }
          .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
            text-align: center;
            color: black;
          }
          .booking-details {
            margin: 10px 0;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <img src="${logoUrl}" alt="Logo" />
          <h1>Thank you for choosing us!</h1>
          <p>One of our team members will reach out to you shortly. You can find the details of your booking below.</p>
          <br />
          <h1>Booking Details</h1>
          <div class="booking-details">
            <h3>Departure:</h3>
            ${bookingData.pickupDate ? `<p><strong>Pick Up Date and Time:</strong> ${bookingData.pickupDate}</p>` : ''}
            ${bookingData.pickupLocation ? `<p><strong>Pick Up Location:</strong> ${bookingData.pickupLocation}</p>` : ''}
            ${bookingData.dropOffLocation ? `<p><strong>Drop Off Location:</strong> ${bookingData.dropOffLocation}</p>` : ''}
            ${bookingData.peopleCount ? `<p><strong>Number of People:</strong> ${bookingData.peopleCount}</p>` : ''}
            ${bookingData.selectedVehicle ? `<p><strong>Selected Vehicle:</strong> ${bookingData.selectedVehicle}</p>` : ''}
            ${bookingData.infantSeat ? `<p><strong>Infant Seat:</strong> ${bookingData.infantSeat}</p>` : ''}
            ${bookingData.childSeat ? `<p><strong>Child Seat:</strong> ${bookingData.childSeat}</p>` : ''}
            ${bookingData.handBaggage ? `<p><strong>Hand Baggage:</strong> ${bookingData.handBaggage}</p>` : ''}
            ${bookingData.baggage ? `<p><strong>Baggage:</strong> ${bookingData.baggage}</p>` : ''}
            ${bookingData.additionalPickupDropOff ? `<p><strong>Additional Pickup/Drop Off:</strong> ${bookingData.additionalPickupDropOff}</p>` : ''}
            ${bookingData.tipTheDriver ? `<p><strong>Tip the Driver (optional):</strong> $${bookingData.tipTheDriver * 20}</p>` : ''}
            
            ${bookingData.isReturn ? `
              <h3>Return:</h3>
              ${bookingData.returnDate ? `<p><strong>Return Date and Time:</strong> ${bookingData.returnDate}</p>` : ''}
              ${bookingData.returnPickupLocation ? `<p><strong>Return Pick Up Location:</strong> ${bookingData.returnPickupLocation}</p>` : ''}
              ${bookingData.returnDropOffLocation ? `<p><strong>Return Drop Off Location:</strong> ${bookingData.returnDropOffLocation}</p>` : ''}
              ${bookingData.returnPeopleCount ? `<p><strong>Number of People:</strong> ${bookingData.returnPeopleCount}</p>` : ''}
              ${bookingData.returnSelectedVehicle ? `<p><strong>Selected Vehicle:</strong> ${bookingData.returnSelectedVehicle}</p>` : ''}
              ${bookingData.returnInfantSeat ? `<p><strong>Infant Seat:</strong> ${bookingData.returnInfantSeat}</p>` : ''}
              ${bookingData.returnChildSeat ? `<p><strong>Child Seat:</strong> ${bookingData.returnChildSeat}</p>` : ''}
              ${bookingData.returnHandBaggage ? `<p><strong>Hand Baggage:</strong> ${bookingData.returnHandBaggage}</p>` : ''}
              ${bookingData.returnBaggage ? `<p><strong>Baggage:</strong> ${bookingData.returnBaggage}</p>` : ''}
              ${bookingData.returnAdditionalPickupDropOff ? `<p><strong>Additional Pickup/Drop Off:</strong> ${bookingData.returnAdditionalPickupDropOff}</p>` : ''}
              ${bookingData.returnTipTheDriver ? `<p><strong>Tip the Driver (optional):</strong> $${bookingData.returnTipTheDriver * 20}</p>` : ''}
            ` : ''}
            
            <br />
            ${bookingData.userPhone ? `<p><strong>Phone Number:</strong> ${bookingData.userPhone}</p>` : ''}
            ${bookingData.userAddress ? `<p><strong>Address:</strong> ${bookingData.userAddress.line1} ${bookingData.userAddress.line2}, ${bookingData.userAddress.city}, ${bookingData.userAddress.province}, ${bookingData.userAddress.postalCode}</p>` : ''}
            ${bookingData.selectedPaymentMethod ? `<p><strong>Selected Payment Method:</strong> ${bookingData.selectedPaymentMethod}</p>` : ''}
            <br />
            ${bookingData.totalPrice ? `<p><strong>Total:</strong>${bookingData.totalPrice} CAD</p>` : ''}
          </div>
          <div>
            <br />
            <p>Click the link below to add the event to your Google Calendar:</p>
            <a href="${googleCalendarLink}">Add trip to Google Calendar</a>
            ${bookingData.isReturn ? `
              <p>Click the link below to add the return event to your Google Calendar:</p>
              <a href="${generateReturnGoogleCalendarLink(bookingData)}">Add return trip to Google Calendar</a>
            ` : ''}          
          </div>
            <br />
            <p>If you have any questions or need to make adjustmens to your booking, you can give us a call at 1 (382) 885-5589 or simply reply to this email.</p>
        </div>
          
    `,
  };

  try {
    const response = await axios.post('https://api.elasticemail.com/v2/email/send', emailData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        apiKey: elasticEmailApiKey,
      },
    });
    //console.log('Email sent:', response.data);
  } catch (error) {
    //console.error('Error sending email:', error);
  }
};

const generateGoogleCalendarLink = (bookingData) => {
  const pickupDate = new Date(bookingData.pickupDate);
  //const endDate = bookingData.isReturn ? new Date(bookingData.returnDate) : pickupDate;

  const formattedStartDate = pickupDate.toISOString().replace(/-|:|\.\d{3}/g, '');
  const formattedEndDate = pickupDate.toISOString().replace(/-|:|\.\d{3}/g, '');

   // Check if pickupLocation contains the word "airport"
  const isPickupLocationAirport = bookingData.pickupLocation.toLowerCase().includes('airport');

  // Set location based on the condition
  const location = isPickupLocationAirport ? `${bookingData.pickupLocation}` : `${bookingData.userAddress.line1} ${bookingData.userAddress.line2}, ${bookingData.userAddress.city}, ${bookingData.userAddress.province}, ${bookingData.userAddress.postalCode}`;

  const title = 'Departure Trip Booking'; 
  const details = `
    Pick Up Date and Time: ${bookingData.pickupDate}
    Pick Up Location: ${bookingData.pickupLocation}
    Drop Off Location: ${bookingData.dropOffLocation}
    Number of People: ${bookingData.peopleCount}
    Selected Vehicle: ${bookingData.selectedVehicle}
    ${bookingData.infantSeat ? `Infant Seat: ${bookingData.infantSeat}` : ''}
    ${bookingData.childSeat ? `Child Seat: ${bookingData.childSeat}` : ''}
    ${bookingData.handBaggage ? `Hand Baggage: ${bookingData.handBaggage}` : ''}
    ${bookingData.baggage ? `Baggage: ${bookingData.baggage}` : ''}
    ${bookingData.additionalPickupDropOff ? `Additional Pickup/Drop Off: ${bookingData.additionalPickupDropOff}` : ''}
    ${bookingData.tipTheDriver ? `Tip the Driver (optional): $${bookingData.tipTheDriver * 20}` : ''}
    Phone Number: ${bookingData.userPhone}
    Address: ${bookingData.userAddress.line1} ${bookingData.userAddress.line2}, ${bookingData.userAddress.city}, ${bookingData.userAddress.province}, ${bookingData.userAddress.postalCode}
    Selected Payment Method: ${bookingData.selectedPaymentMethod}
    Total (departure):${bookingData.price} CAD 
  `;

  //    Trip Type: ${bookingData.isReturn ? 'Return' : 'One Way'} 

  const googleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${formattedStartDate}/${formattedEndDate}&details=${details}&location=${location}`;

  return encodeURI(googleCalendarLink);
};

const generateReturnGoogleCalendarLink = (bookingData) => {
  // Create a separate Google Calendar link for return details
  const pickupDate = new Date(bookingData.returnDate);
  const formattedStartDate = pickupDate.toISOString().replace(/-|:|\.\d{3}/g, '');
  const formattedEndDate = pickupDate.toISOString().replace(/-|:|\.\d{3}/g, '');

  const title = 'Return Trip Booking';
  const location = `${bookingData.returnPickupLocation}`;
  const details = `
    Return Date and Time: ${bookingData.returnDate}
    Return Pick Up Location: ${bookingData.returnPickupLocation}
    Return Drop Off Location: ${bookingData.returnDropOffLocation}
    Number of People: ${bookingData.returnPeopleCount}
    Selected Vehicle: ${bookingData.returnSelectedVehicle}
    ${bookingData.returnInfantSeat ? `Infant Seat: ${bookingData.returnInfantSeat}` : ''}
    ${bookingData.returnChildSeat ? `Child Seat: ${bookingData.returnChildSeat}` : ''}
    ${bookingData.returnHandBaggage ? `Hand Baggage: ${bookingData.returnHandBaggage}` : ''}
    ${bookingData.returnBaggage ? `Baggage: ${bookingData.returnBaggage}` : ''}
    ${bookingData.returnAdditionalPickupDropOff ? `Additional Pickup/Drop Off: ${bookingData.returnAdditionalPickupDropOff}` : ''}
    ${bookingData.returnTipTheDriver ? `Tip the Driver (optional): $${bookingData.returnTipTheDriver * 20}` : ''}
    Phone Number: ${bookingData.userPhone}
    Address: ${bookingData.userAddress.line1} ${bookingData.userAddress.line2}, ${bookingData.userAddress.city}, ${bookingData.userAddress.province}, ${bookingData.userAddress.postalCode}
    Selected Payment Method: ${bookingData.selectedPaymentMethod}
    Total (return):${bookingData.returnPrice} CAD
  `;

   // Trip Type: ${bookingData.isReturn ? 'Return' : 'One Way'}

  const returnGoogleCalendarLink = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${formattedStartDate}/${formattedEndDate}&details=${details}&location=${location}`;
  return encodeURI(returnGoogleCalendarLink);
};

const sendEmailContactForm = async (contactForm, adminEmail) => {
  const emailData = {
    //todo: update the from email and name
    from: 'info@luxtransport.ca',
    fromName: 'Lux Transport',
    subject: 'New Contact Form Submitted',
    to: `${adminEmail}`, 
    body: `
      <!DOCTYPE html>
      <html>
      <head>
        <style>
          /* Add your email styling here */
          body {
            font-family: Arial, sans-serif;
          }
          .container {
            max-width: 600px;
            margin: 0 auto;
            padding: 20px;
          }
          h2 {
            color: #333;
          }
          .booking-details {
            margin: 10px 0;
          }
        </style>
      </head>
      <body>
        <div class="container">
          <h1>Contact Form Details</h1>
          <div class="booking-details">
            ${contactForm.name ? `<p><strong>Sender's Name:</strong> ${contactForm.name}</p>` : ''}
            ${contactForm.email ? `<p><strong>Sender's Email:</strong> ${contactForm.email}</p>` : ''}
            ${contactForm.message ? `<p><strong>Sender's Message:</strong> ${contactForm.message}</p>` : ''}
          </div>
          <div>
            <br />
          </div>
        </div>
          
    `,
  };

  try {
    const response = await axios.post('https://api.elasticemail.com/v2/email/send', emailData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params: {
        apiKey: elasticEmailApiKey,
      },
    });
    //console.log('Email sent:', response.data);
  } catch (error) {
    //console.error('Error sending email:', error);
  }
};

async function getAdminEmail() {
    // const adminsCollection = collection(db, 'users');
    // const adminsQuery = query(adminsCollection, where('isAdmin', '==', true));
    // const adminsSnapshot = await getDocs(adminsQuery);

    // if (!adminsSnapshot.empty) {
    //   // Assuming there's only one admin with isAdmin set to true
    //   const adminData = adminsSnapshot.docs[0].data();
    //   return adminData.email;
    // }

    return 'info@luxtransport.ca'
  }

export { sendEmail, sendEmailContactForm, getAdminEmail };
